import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LinkWithIcon from "../../ui/LinkButtons/LinkWithIcon/LinkWithIcon";
import ButWithIcon from "../../ui/buttons/ButWithIcon/ButWithIcon";
import AppointmentModal from "../AppointmentModal/AppointmentModal";
import css from "./DirectoryToolbar.module.css";

const DirectoryToolbar = ({ record, isCanChange }) => {
    const location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalOption, setModalOption] = useState(""); 

    //для модалки    
    const onOpenModal = () => {
        setIsModalOpen(true);
        window.addEventListener('keydown', onEscKeyPress);
    
    
    };
    //для модалки   
    const onEscKeyPress=(event)=> {
        const ESC_KEY_CODE = 'Escape';
        const isEscKey = event.code === ESC_KEY_CODE;
        if (isEscKey) {
            onClose();
        }
    };
    //для модалки   
    const onClose= () => {
        setIsModalOpen(false);
        window.removeEventListener('keydown', onEscKeyPress);
        setModalOption("");
        
        };

  const delRecord = () => {
    setModalOption("delete");
    onOpenModal();
  };

  const tooltipContent=isCanChange ? "Видалити" : "Видалення неможливе. Є запис на прийом або у графіку";

  return (
    <div className={css.wrapper}>
      <ul className={css.toolList}>
        <li key={'edit_dir'}>
            <LinkWithIcon 
              src={`../../edit_directory`} 
              state={{ from: location, item: record}} 
              tooltipId="tooltip-edit" 
              tooltipContent="Редагувати" 
              icon="icon-pencil" 
              size="small"
            />
        </li>
        <li key={'delete_dir'}>
            <ButWithIcon onClick={delRecord} disabled={!isCanChange} tooltipId="tooltip-delete" tooltipContent={tooltipContent} icon="icon-trash"/>
        </li>
  
        {isModalOpen && (
          <AppointmentModal
            onClose={onClose}
            modalOption={modalOption}
            currentItem={record}
            text="Ви впевнені, що хочете видалити запис з довідника?"
            type="directory"
          />
        )}
      </ul>
    </div>
  );
};

export default DirectoryToolbar;