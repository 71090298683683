import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Toaster } from "react-hot-toast";
import Button from "../../ui/buttons/Button/Button";
import MessageNotification from "../MessageNotification/MessageNotification";
import { createSchedule } from "../../redux/schedules/schedulesOperations";
import { useAuth } from "../../hooks/useAuth";
import css from "./ModalAddDoctor.module.css";
import "../../styles/variables.css";

const ModalAddDoctor = ({ currentItem, onClose, text, type, date, doctors, time }) => {
    const dispatch = useDispatch();
    const initialValues = {
        doctor: `${doctors[0]?.name} ${doctors[0]?.surname}`,
    };

    const { user } = useAuth();

    const handleSubmit = async(values, { resetForm }) => {
        const doctorForm = doctors.find((doctor) => `${doctor.name} ${doctor.surname}` === values.doctor);
        let message;
        try {
            const query = {
                date: date,
                time: time,
                doctor_id: doctorForm.doctor_id,
                user_id: user.user_id,
            };
            await dispatch(createSchedule(query))
            .then((res)=>{
                if(res.payload.status===201){
                    message=`Запис у графік додано`;
                    MessageNotification("success", message);
                    console.log("ModalAddDoctor", message)

                }else if(res.error.message==="Rejected"){
                    message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                    MessageNotification("error", message);
                    console.log("ModalAddDoctor else if", message)

                }
            })
            .catch(error => {
                message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                MessageNotification("error", message);
                console.log("ModalAddDoctor catch dispatch", message)

            });

        } catch (error) {
            message= "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
            MessageNotification("error", message);
            console.log("ModalAddDoctor catch try", message)
        }
        handleCancel(resetForm);
    };

    const handleCancel = (resetForm) => {
        resetForm();
        onClose();
    };

    return (
        <div className={css.wrapper}>
            <p>{text}</p>
            <Toaster />
            <Formik initialValues={initialValues} onSubmit={handleSubmit} isValidating>
                {({ values, setFieldValue, resetForm }) => (
                    <Form>
                        <div role="group" aria-labelledby="my-radio-group" className={css.doctorsWrapper}>
                            {doctors?.length > 0 &&
                                doctors.map(({ doctor_id, name, surname }) => (
                                    <label className={css.customRadio} key={`doctor${doctor_id}`}>
                                        <Field type="radio" name="doctor" value={`${name} ${surname}`} />
                                        <span>{`${name} ${surname}`}</span>
                                    </label>
                                ))}
                        </div>

                        <div className={css.butWrapper}>
                            <Button type="submit" className="Ok">
                                Додати
                            </Button>
                            <Button type="button" onClick={() => handleCancel(resetForm)} className="cancel">
                                Відміна
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ModalAddDoctor;
