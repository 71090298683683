import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Toaster } from "react-hot-toast";
import isEmpty from "lodash.isempty";
import { apiGetColumnsTable } from "../../api/common";
import Button from "../../ui/buttons/Button/Button";
import DynamicFieldFormControl from "../DynamicFieldForm/DynamicFieldFormControl";
import MessageNotification from "../MessageNotification/MessageNotification";
import { useAuth } from "../../hooks/useAuth";
import { createDirectory, updateDirectory } from "../../redux/directories/directoriesOperations";
import { registerUser } from "../../redux/auth/authOperations";
import css from "./DirectoryForm.module.css";
import { addExcludes } from "../../constants";

const DirectoryForm = ({ type }) => {
    const location = useLocation();
    console.log("location?.state?.item=", location?.state?.item);
    const dispatch = useDispatch();
    const { user } = useAuth();

    const [tableColumns, setTableColumns] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const getColumnsTable = async () => {
            try {
                const result = await apiGetColumnsTable(location.state?.item);
                setTableColumns(result);
            } catch (error) {
                const message = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", message);
            }
        };

        if (location?.state?.item && type === "add") {
            getColumnsTable();
        }
        if (location?.state?.item && type === "edit") {
            const arr = Object.keys(location?.state?.item);
            setTableColumns(arr);
        }
    }, [location?.state?.item, type]);

    useEffect(() => {
        if (!tableColumns.length > 0) {
            return;
        }

        // Инициализация начальных значений для каждого поля
        const initial = {};
        for (let i = 0; i < tableColumns?.length; i += 1) {
            if (type === "add") {
                if (addExcludes.includes(tableColumns[i])) {
                    continue;
                }
                if (location?.state?.item === "users" && (tableColumns[i].includes("name") || tableColumns[i].includes("surname"))) {
                    continue;
                }
            }
            if (type === "edit") {
                if (tableColumns[i].includes("id") || tableColumns[i].includes("user") || tableColumns[i].includes("createdAt") || tableColumns[i].includes("updatedAt")) {
                    continue;
                }
            }

            if (tableColumns[i].includes("time")) {
                if (type === "add") {
                    initial.hours = "";
                    initial.minutes = "";
                }
                if (type === "edit") {
                    initial.hours = location?.state?.item[tableColumns[i]].slice(0, 2);
                    initial.minutes = location?.state?.item[tableColumns[i]].slice(3, 5);
                }
                continue;
            }
            if (type === "add") {
                initial[tableColumns[i]] = "";
            }
            if (type === "edit") {
                // if (tableColumns[i].includes("avatar")){
                //     initial[tableColumns[i]]=""

                // }else if (tableColumns[i].includes("password")){
                //     initial[tableColumns[i]]=""

                // }else{
                initial[tableColumns[i]] = location?.state?.item[tableColumns[i]];
                //}
            }
        }

        console.log("initial=", initial);
        setInitialValues(initial);
    }, [tableColumns, type, location?.state?.item]);

    const handleSubmit = async (values, { resetForm }) => {
        let query = {};
        // let res;
        let message;
        let nameDirectory;

        console.log("values=", values);

        if (type === "add") {
            if (location?.state?.item === "workhours") {
                const timeForQuery = values.hours.padStart(2, "0") + ":" + values.minutes.padStart(2, "0") + ":00";
                query = { time: timeForQuery, user_id: user.user_id, active: true };
            } else if (location?.state?.item === "users") {
                query = { ...values, active: true };
            } else {
                query = { ...values, user_id: user.user_id, active: true };
            }

            console.log("query=", query);
            try {
                nameDirectory = location?.state?.item;

                if (nameDirectory === "users") {
                    await dispatch(registerUser(query))
                        .then((res) => {
                            console.log("DirectoryForm res=", res);

                            if (res.payload?.user) {
                                message = `Користувача у довідник додано`;
                                MessageNotification("success", message);
                            } else if (res.error.message === "Rejected") {
                                if (res.payload.includes(409)) {
                                    message = "Такий логін вже існує";
                                } else {
                                    message = `Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`;
                                }
                                MessageNotification("error", message);
                                console.log("DirectoryForm else if message=", message);
                            }
                        })
                        .catch((error) => {
                            message = `Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                            MessageNotification("error", message);
                        });
                } else {
                    await dispatch(createDirectory({ nameDirectory, query }))
                        .then((res) => {
                            console.log("DirectoryForm res=", res);
                            if (res.payload.status === 201) {
                                message = `Запис у довідник додано`;
                                MessageNotification("success", message);
                            } else if (res.error.message === "Rejected") {
                                message = `Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`;
                                MessageNotification("error", message);
                                console.log("DirectoryForm else if message=", message);
                            }
                        })
                        .catch((error) => {
                            message = `Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                            MessageNotification("error", message);
                        });
                }
            } catch (error) {
                message = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", message);
            }
        }

        if (type === "edit") {
            const nameID = tableColumns[0].slice(0, tableColumns[0].length - 3);
            const id = location?.state?.item[tableColumns[0]];
            if (nameID === "workhour") {
                const timeForQuery = values.hours.padStart(2, "0") + ":" + values.minutes.padStart(2, "0") + ":00";
                query = { time: timeForQuery, user_id: user.user_id, active: values.active };
            } else if (nameID === "user") {
                for (const key in values) {
                    if (values[key] !== location?.state?.item[key]) {
                        console.log("values[key]=", values[key]);
                        console.log("location?.state?.item[key]=", location?.state?.item[key]);
                        query[key] = values[key];
                    }
                }
            } else {
                query = { ...values, user_id: user.user_id };
            }
            nameDirectory = nameID + "s";
            console.log("nameDirectory=", nameDirectory);
            console.log("query=", query);

            try {
                await dispatch(updateDirectory({ nameDirectory, query, id }))
                    .then((res) => {
                        if (res.payload.status === 200) {
                            message = `Зміни внесені`;
                            MessageNotification("success", message);
                        } else if (res.error.message === "Rejected") {
                            message = `DirectoryForm Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`;
                            MessageNotification("error", message);
                        }
                    })
                    .catch((error) => {
                        message = `dispatch DirectoryForm Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);
                    });
            } catch (error) {
                message = "try DirectoryForm Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", message);
            }
        }

        handleCancel(resetForm);
    };

    const handleCancel = (resetForm) => {
        resetForm();
        // Переход на предыдущую страницу
        navigate(-1);
    };

    return (
        <>
            <div className="container">
                {type === "add" && <h2>Додавання запису до таблиці {location?.state?.item}</h2>}
                {type === "edit" && <h2>Редагування запису</h2>}
                <Toaster />
                {!isEmpty(initialValues) && (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} isValidating>
                        {({ setFieldValue, resetForm }) => (
                            <Form>
                                {tableColumns?.map((column, index) => {
                                    let isRender = true;
                                    if (type === "add") {
                                        isRender = addExcludes.includes(column) ? false : true;
                                        if (location?.state?.item === "users" && (column.includes("name") || column.includes("surname"))) {
                                            isRender = false;
                                        }
                                    }
                                    if (type === "edit") {
                                        isRender = column.includes("id") || column.includes("user") || column.includes("token") || column.includes("createdAt") || column.includes("updatedAt") ? false : true;
                                    }

                                    return (
                                        <React.Fragment key={`${column}${index}`}>
                                            {isRender ? initialValues?.avatar ? <DynamicFieldFormControl field={column} setFieldValue={setFieldValue} avatarURL={initialValues.avatar} /> : <DynamicFieldFormControl field={column} /> : null}
                                        </React.Fragment>
                                    );
                                })}

                                <div className={css.butWrapper}>
                                    {type === "add" && (
                                        <Button type="submit" className={"formsButton"}>
                                            Додати запис у довідник
                                        </Button>
                                    )}
                                    {type === "edit" && (
                                        <Button type="submit" className={"formsButton"}>
                                            Зберегти зміни у довіднику
                                        </Button>
                                    )}
                                    <Button type="button" onClick={() => handleCancel(resetForm)} className={"formsButton"}>
                                        Відміна
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    );
};

export default DirectoryForm;
