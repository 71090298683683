import { Puff } from "react-loader-spinner"
import css from "./Loader.module.css";

const Loader = ({ type }) => {
  if (type === 'suspense') {
    return (
      <div className={css.suspenseWrapper}>
        <Puff
          width='200'
          color="#3E85F3"
          ariaLabel="puff-loading"
        />
      </div>
    )
  }
  return (
    <div className={css.wrapper}>
      <Puff
        width='200'
        color="white"
        ariaLabel="puff-loading"
      />
    </div>
  )
}

export default Loader;