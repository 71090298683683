import DirectoryForm from "../../components/DirectoryForm/DirectoryForm";

const AddDirectoryPage=()=>{


    return (
        <>
        <DirectoryForm type="add"/>
        </>
    );

}
export default AddDirectoryPage