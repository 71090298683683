import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datePicker.css";
import { Toaster } from "react-hot-toast";
import Button from "../../ui/buttons/Button/Button";
import * as schedulesAPI from "../../api/schedules";
import * as doctorsAPI from "../../api/doctors";
import * as workhoursAPI from "../../api/workhours";
import { useAuth } from "../../hooks/useAuth";
import { createSchedule } from "../../redux/schedules/schedulesOperations";
import isSunday from "../../services/isSunday";
import MessageNotification from "../MessageNotification/MessageNotification";
import "../../styles/variables.css";
import css from "./ScheduleForm.module.css";

const ScheduleForm = ({ type }) => {
    let initialValues = null;
    if (type === "add") {
        initialValues = {
            date: new Date(),
            time: [],
            doctor: "",
        };
    }

    const [freeWorkingTime, setFreeWorkingTime] = useState([]);
    const [workhours, setWorkHours] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [schedules, setSchedules] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);

    const dispatch = useDispatch();
    const { user } = useAuth();

    useEffect(() => {
        if (!selectedDate) {
            return;
        }

        const getSchedulesByDate = async (date) => {
            try {
                const response = await schedulesAPI.apiGetSchedules({ date: date.toISOString().slice(0, 10) });
                if (response?.data?.length > 0) {
                    setSchedules(response?.data);
                }
            } catch (error) {
                const errorMessage = "Помилка при завантаженні графіку за дату :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", errorMessage);
            }
        };

        const getDoctors = async () => {
            try {
                const apiDoctors = await doctorsAPI.apiGetDoctors();
                const activeDoctors = apiDoctors?.data?.filter((doctor) => doctor.active === true);
                setDoctors(activeDoctors);
            } catch (error) {
                const errorMessage = "Помилка при завантаженні лікарів :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", errorMessage);
            }
        };

        const getWorkhours = async () => {
            try {
                const apiWorkHours = await workhoursAPI.apiGetWorkhours();
                const activeWorkHours = apiWorkHours?.data?.filter((workHour) => workHour.active === true);
                setWorkHours(activeWorkHours);
            } catch (error) {
                const errorMessage = "Помилка при завантаженні робочих годин :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", errorMessage);
            }
        };

        getDoctors();
        getWorkhours();
        getSchedulesByDate(selectedDate);
    }, [selectedDate]);

    const handleSubmit = async (values, { resetForm }) => {
        console.log("values=", values);
        let message;
        let errorRes = [];
        let successRes = [];
        if (values.time.length === 0) {
            MessageNotification("error", `Оберіть час запису`);
        } else {
            const queryDate = values.date.toISOString().slice(0, 10);
            let query = null;
            console.log("handleSubmit query=", query);

            try {
                if (type === "add") {
                    for (let i = 0; i < values.time.length; i += 1) {
                        query = {
                            ...values,
                            date: queryDate,
                            time: values.time[i],
                            doctor_id: selectedDoctor.doctor_id,
                            user_id: user.user_id,
                        };
                        delete query.doctor;

                        for (const key in query) {
                            if (query[key] === "") {
                                delete query[key];
                            }
                        }

                        await dispatch(createSchedule(query)).then((res) => {
                            if (res.payload.status === 201) {
                                successRes.push(res.payload);
                            } else if (res.error.message === "Rejected") {
                                errorRes.push(res.error.message);
                            }
                        });
                    }
                }

                if (successRes.length === 0 && errorRes.length > 0) {
                    message = "Помилка! Жодного запису у графік не збережено!";
                    MessageNotification("error", message);
                }
                if (successRes.length > 0 && errorRes.length === 0) {
                    message = "Всі записи у графік додано";
                    MessageNotification("success", message);
                } else if (successRes.length > 0 && errorRes.length > 0) {
                    let timeRes = "";
                    for (let i = 0; i < successRes.length; i += 1) {
                        timeRes = timeRes + "\n" + successRes[i].data.time.slice(0, 5);
                    }
                    message = `Записи у графік збережено частково. Збережено час: ${timeRes}`;
                    MessageNotification("error", message);
                }
            } catch (error) {
                message = "Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n" + error.toString();
                MessageNotification("error", message);
            }
            handleCancel(resetForm);
        }
    };

    const handleCancel = (resetForm) => {
        setSelectedDate(null);
        setFreeWorkingTime([]);
        setSchedules([]);
        setSelectedDoctor(null);
        setDoctors([]);
        setIsLoadingSchedules(false);
        resetForm();
    };

    //если это не воскресенье, то выбранная в календаре дата отрисовывается в input
    const handleDateChange = (date, setFieldValue) => {
        if (!isSunday(date)) {
            setFieldValue("date", date);
            setSelectedDate(date);
        }
    };

    const handleDoctorChange = async (e) => {
        // console.log(e.target)
        const selectedDoc = e.target.value;
        // console.log("selectedDoc=", selectedDoc)

        const choosedDocObject = doctors.find((doc) => selectedDoc === `${doc.name} ${doc.surname}`);
        setSelectedDoctor(choosedDocObject);

        const freeWorkingTimeForDoctor = [];

        workhours.forEach(function (workhour, index) {
            const isInSchedule = schedules?.find((schedule) => schedule.time === workhour.time);

            if (!isInSchedule) {
                freeWorkingTimeForDoctor.push(workhour);
            }
        });

        setFreeWorkingTime(freeWorkingTimeForDoctor);

        setIsLoadingSchedules(true);
    };

    return (
        <div className="container">
            {/* <h3 className={css.formTitle}>Форма запису в графік</h3> */}
            <h2 className="caption">
                <center>Запис в графік</center>
            </h2>
            <Toaster />
            <Formik initialValues={initialValues} onSubmit={handleSubmit} isValidating>
                {({ values, setFieldValue, resetForm }) => (
                    <Form>
                        <label htmlFor="date" className={css.formLabel}>
                            <p className={css.calendarText}>
                                Оберіть дату запису у графік<span className={css.accent}>*</span>
                            </p>
                        </label>
                        <Field name="date">
                            {({ field }) => {
                                const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
                                    <button type="button" className={className} onClick={onClick} ref={ref} disabled={isLoadingSchedules}>
                                        {value}
                                    </button>
                                ));
                                return (
                                    <DatePicker
                                        selected={field.value}
                                        onChange={(date) => handleDateChange(date, setFieldValue)}
                                        customInput={<ExampleCustomInput className="example-custom-input" />}
                                        shouldCloseOnSelect={true}
                                        locale="uk"
                                        dateFormat="dd MMMM yyyy"
                                        minDate={new Date()}
                                        // воскресеньям добавляется класс стилей sunday
                                        dayClassName={(date) => (isSunday(date) ? "sunday" : undefined)}
                                    />
                                );
                            }}
                        </Field>

                        {/* выводится список докторов из графика на выбранную дату*/}
                        {selectedDate && (
                            <>
                                <div className={css.line}></div>
                                <div id="my-radio-group">
                                    Оберіть лікаря<span className={css.accent}>*</span>
                                </div>
                                <div role="group" aria-labelledby="my-radio-group" className={css.doctorsWrapper}>
                                    {doctors.map(({ doctor_id, name, surname }) => (
                                        <label className={css.customRadio} key={`doctor${doctor_id}`}>
                                            <Field type="radio" name="doctor" value={`${name} ${surname}`} onClick={(doctor) => handleDoctorChange(doctor)} />
                                            <span>{`${name} ${surname}`}</span>
                                        </label>
                                    ))}
                                </div>
                            </>
                        )}

                        {freeWorkingTime?.length > 0 && (
                            <>
                                <div className={css.line}></div>
                                <div id="checkbox-group" className={css.timeHeader}>
                                    Оберіть час прийому<span className={css.accent}>*</span>
                                </div>
                                <div role="group" aria-labelledby="checkbox-group">
                                    <ul className={css.timeWrapper}>
                                        {freeWorkingTime?.map(({ workhour_id, time }) => {
                                            return (
                                                <li key={workhour_id}>
                                                    <Field type="checkbox" id={`time${workhour_id}`} name="time" value={time} className={css.timeInput} />
                                                    <label htmlFor={`time${workhour_id}`} className={css.timeLabel}>
                                                        {time.slice(0, 5)}
                                                    </label>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className={css.line}></div>

                                <div className={css.butWrapper}>
                                    <Button type="submit">Додати у графік</Button>
                                    <Button type="button" onClick={() => handleCancel(resetForm)}>
                                        Відміна
                                    </Button>
                                </div>
                            </>
                        )}
                        {isLoadingSchedules && freeWorkingTime?.length === 0 && (
                            <>
                                <p className={css.timeInfo}>Немає вільних місць на обрану дату</p>
                                <Button type="button" onClick={() => handleCancel(resetForm)}>
                                    Відміна
                                </Button>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ScheduleForm;
