import { createSelector } from "@reduxjs/toolkit";

export const selectMonth = (state) => state.appointments.month;

export const selectWeek = (state) => state.appointments.week;

export const selectDay = (state) => state.appointments.day;

export const selectYear = (state) => state.appointments.year;

export const selectAppointments = (state) => state.appointments.items;

export const selectFullDate = createSelector(
    [selectDay, selectMonth, selectYear],
    (
        day,
        // week,
        month,
        year
    ) => {
        const fullDay = day.toString().length === 1 ? day.toString().padStart(2, "0") : day;
        const isoMonth = month + 1;
        const fullMonth = isoMonth.toString().length === 1 ? isoMonth.toString().padStart(2, "0") : isoMonth;

        return `${year}-${fullMonth}-${fullDay}`;
    }
);

export const selectIsLoadingAppointments = (state) => state.appointments.isLoadingAppointments;

export const selectErrorAppointments=(state)=>state.appointments.error;

// export const selectWeek = createSelector([selectWeek], (week) => {
//     // const week = week;
//     return week;
// });

export const selectStatusAppointments=(state)=>state.appointments.status;
