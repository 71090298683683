import AppointmentForm from "../../components/AppointmentForm/AppointmentForm";


const AddAppointmentPage=()=>{


    return (
        <AppointmentForm type="add"/>  
    );

}
export default AddAppointmentPage