import css from "./NameApp.module.css";

const NameApp = () => {
    return (
        <div className={css.title}>
            EglaMed <span className={css.caption}>Calendar</span>
        </div>
    );
};
export default NameApp;
