import axios from "axios";
import { baseURL } from "../constants";

export const instance = axios.create({
    baseURL: baseURL,
});

// Utility to add JWT
export const setToken = (token) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
// Utility to remove JWT
export const deleteToken = () => {
    console.log(instance.defaults.headers.common["Authorization"]);
    delete instance.defaults.headers.common["Authorization"];
};
