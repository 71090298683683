import { instance, setToken } from "./instance";

/*
 * GET @ /users/current
 * headers: Authorization: Bearer token
 */
export const refresh = async (token) => {
    // console.log("persistedToken", token);
    try {
        setToken(token);
        const { data } = await instance.get("api/users/current");
        // console.log("response data in users.js : ", data);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiEditUser = async (user, id) => {
    console.log("apiEditUser user=", user)
    console.log("apiEditUser id=", id)
    try{
        const {data} = await instance.put(`/api/users/edit/${id}`, user);
        console.log("apiEditUser data=", data)
        return data;
    }catch (error){
        throw new Error(error.message);
    }
};

export const apiGetUsers = async () => {
    try{     
        const result = await instance.get(`/api/users`);
        return result;
    }catch (error){
        throw new Error(error.message);
    }
}

export const apiDeleteUser = async (id) => {
    try {
        const result = await instance.delete(`/api/users/${id}`);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};
