import { createSelector } from "@reduxjs/toolkit";

export const selectScheduleMonth = (state) => state.schedules.scheduleMonth;

export const selectScheduleWeek = (state) => state.schedules.scheduleWeek;

export const selectScheduleDay = (state) => state.schedules.scheduleDay;

export const selectScheduleYear = (state) => state.schedules.scheduleYear;

export const selectSchedules = (state) => state.schedules.items;

export const selectScheduleFullDate = createSelector(
    [selectScheduleDay, selectScheduleMonth, selectScheduleYear],
    (
        day,
        // week,
        month,
        year
    ) => {
        const fullDay = day.toString().length === 1 ? day.toString().padStart(2, "0") : day;
        const isoMonth = month + 1;
        const fullMonth = isoMonth.toString().length === 1 ? isoMonth.toString().padStart(2, "0") : isoMonth;

        return `${year}-${fullMonth}-${fullDay}`;
    }
);

export const selectIsLoadingSchedules = (state) => state.schedules.isLoadingSchedules;

export const selectError=(state)=>state.schedules.error;

export const selectWeek = createSelector([selectScheduleWeek], (week) => {
    // const week = week;
    return week;
});
