import toast from "react-hot-toast";
import { FiBell } from "react-icons/fi";
import "../../styles/variables.css";

const MessageNotification=(type, message)=>{

    return (
        <>
        
        {type==="error" &&
            (toast.error(message, {
            duration: 5000,
            position: "top-center",
            icon: <FiBell size={24} color={getComputedStyle(document.documentElement).getPropertyValue('--accent-color')} />
            }))
        }
        {type==="success" &&
            (toast.error(message, {
            duration: 3000,
            position: "top-center",
            icon: <FiBell size={24} color={getComputedStyle(document.documentElement).getPropertyValue('--correct-message-color')} />
            }))
        }        
        
        </>

    
    );
}

export default MessageNotification;
