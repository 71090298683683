import DynamicTextFieldForm from "./DynamicTextFieldForm";
import DynamicTimeFieldForm from "./DynamicTimeFieldForm";
import DynamicActiveFieldForm from "./DynamicActiveFieldForm";
import DynamicPasswordFieldForm from "./DynamicPasswordFieldForm";
import DynamicAvatarFieldForm from "./DynamicAvatarFieldForm";

// Компонент для различных типов полей
const DynamicFieldFormControl = ({field, setFieldValue, avatarURL}) => {

    //По умолчанию будет текстовое поле input
    switch (field) {
         
        case "time":
            return (
                <DynamicTimeFieldForm field={field}/>
            );
        case "active":
            return(
                <DynamicActiveFieldForm field={field}/>
            );
        case "password":
                return(
                    <DynamicPasswordFieldForm field={field}/>
                );
        case "avatar":
            return(
                <DynamicAvatarFieldForm field={field} setFieldValue={setFieldValue} avatarURL={avatarURL}/>
            );
        default:
            return (
                <DynamicTextFieldForm field={field}/>
            );
    }



};

export default DynamicFieldFormControl;