import { useAuth } from "../../hooks/useAuth";
import css from "./UserInfo.module.css";

export const UserInfo = () => {
    const { user } = useAuth();
    // console.log("user from UserInfo: ", user);
    return (
        <div className={css.wrapper}>
            <p className={css.userName}>{user.name}</p>
            <div className={css.userPhotoWrapper}>{user.avatar ? <img src={user.avatar} alt="user avatar" className={css.userPhoto} /> : <p className={css.text}>{user.name}</p>}</div>
        </div>
        // <>
        //     <h2>UserInfo</h2>
        // </>
    );
};
