import { useSelector } from "react-redux";
import { selectUser, selectIsLoggedIn, selectIsRefreshing, selectError, selectIsLoading } from "../redux/auth/authSelectors";

export const useAuth = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isRefreshing = useSelector(selectIsRefreshing);
    const user = useSelector(selectUser);
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);

    return {
        isLoggedIn,
        isRefreshing,
        user,
        error,
        isLoading,
    };
};
