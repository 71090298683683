import React from "react";
import PeriodPaginator from "../PeriodPaginator/PeriodPaginator";
import PeriodTypeSelect from "../PeriodTypeSelect/PeriodTypeSelect";
import css from "./CalendarToolbar.module.css";

const CalendarToolbar = ({ periodType, changePeriod, checkDate, page }) => {
    return (
        <div className="container">
            <div className={css.wrapper}>
                <PeriodPaginator periodType={periodType} changePeriod={changePeriod} checkDate={checkDate} page={page} />
                {(page === "calendar" || page === "schedule") ? <PeriodTypeSelect changePeriod={changePeriod} page={page}/> : <></>}
            </div>
        </div>
    );
};

export default CalendarToolbar;
