import { useDispatch } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import Button from "../Button/Button";
import { logOutUser } from "../../../redux/auth/authOperations";

const ButLogOut = ({ caption = "Вийти", className }) => {
    // const ButLogOut = () => {
    const dispatch = useDispatch();

    const handleLogOut = () => {
        dispatch(logOutUser());
    };

    return (
        <Button onClick={handleLogOut} className={className}>
            {caption}
            {/* <Button onClick={handleLogOut}> */}
            {/* Вийти */}
            <FiLogOut />
        </Button>
    );
};

export default ButLogOut;
