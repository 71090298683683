import {Tooltip} from 'react-tooltip';
import sprite from "../../../assets/icons/icons.svg";
import css from "./ButWithIcon.module.css";


const ButWithIcon =({onClick, disabled, tooltipId, tooltipContent, icon, backgroundColorVar, colorVar, borderRadius})=>{
    const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue(backgroundColorVar) || 'transparent';
    const color = getComputedStyle(document.documentElement).getPropertyValue(colorVar) || '#3E85F3';

    return(
        <button 
            type="button"
            className={css.toolButton}
            onClick={onClick}
            disabled={disabled}
            data-tooltip-id={tooltipId}
            data-tooltip-content={tooltipContent}
            style={{ backgroundColor: backgroundColor, color: color, borderRadius: borderRadius || null }} 
        >
            <svg className={css.toolButtonIcon}>
                <use href={`${sprite}#${icon}`}></use>
            </svg>  
            <Tooltip id={tooltipId} place="bottom" style={{ backgroundColor: "#rgba(220, 227, 229, 0.50)", borderRadius: "8px", color: "#FFFFFF"}}/>
        </button>
    );

}

export default ButWithIcon;