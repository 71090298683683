import React, { useState } from "react";
import { DatePickerCustom } from "../DatePickerCustom/DatePickerCustom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/chevron-right.svg";
import { useDispatch } from "react-redux";
import { setCurrentDay, setCurrentMonth, setCurrentYear } from "../../redux/appointments/appointmentsSlice";
import { setScheduleCurrentDay, setScheduleCurrentMonth, setScheduleCurrentYear } from "../../redux/schedules/schedulesSlice";
import { useAppointment } from "../../hooks/useAppointment";
import { useSchedule } from "../../hooks/useSchedule";
import css from "./PeriodPaginator.module.css";

// /** begin week */
// import { startOfWeek, addDays, format, getWeek, setWeek } from "date-fns";

// function getWeekDates(date) {
//     // отримуємо початок тижня
//     const start = startOfWeek(date, { weekStartsOn: 1 }); // встановлюємо Понеділок як початок тижня (weekStartsOn: 1)
//     // отримуємо всі дати тижня
//     const weekDates = [];
//     for (let i = 0; i < 7; i++) {
//         weekDates.push(addDays(start, i));
//     }

//     return weekDates;
// }

// // Використання
// // const date = new Date(2024, 7, 9); // Ваша вказана дата
// // const weekDates = getWeekDates(date);

// // weekDates.forEach((day) => {
// //     console.log(format(day, "yyyy-MM-dd")); // Форматування дати
// // });

// /** end week */

const PeriodPaginator = ({ periodType, changePeriod, checkDate, page }) => {
    const {appointmentFullDate}=useAppointment();
    const {scheduleFullDate}=useSchedule();

    let reduxDate;
    if (page === "calendar") {
        reduxDate = appointmentFullDate;
    }

    if (page === "schedule") {
        reduxDate = scheduleFullDate;
    }

    const currentDate = new Date(reduxDate);
    const dispatch = useDispatch();
    const [filterMonth, setFilterMonth] = useState(currentDate.getMonth());
    const [filterYear, setFilterYear] = useState(currentDate.getFullYear());

    const handlePrevMonth = () => {
        const prevMonthDate = new Date(currentDate);
        prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
        setFilterMonth(prevMonthDate.getMonth());
        setFilterYear(prevMonthDate.getFullYear());

        if (page === "calendar") {
            dispatch(setCurrentDay(prevMonthDate.getDate()));
            dispatch(setCurrentMonth(prevMonthDate.getMonth()));
            dispatch(setCurrentYear(prevMonthDate.getFullYear()));
        }

        if (page === "schedule") {
            dispatch(setScheduleCurrentDay(prevMonthDate.getDate()));
            dispatch(setScheduleCurrentMonth(prevMonthDate.getMonth()));
            dispatch(setScheduleCurrentYear(prevMonthDate.getFullYear()));
        }
    };

    const handleNextMonth = () => {
        const nextMonthDate = new Date(currentDate);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
        setFilterMonth(nextMonthDate.getMonth());
        setFilterYear(nextMonthDate.getFullYear());

        if (page === "calendar") {
            dispatch(setCurrentDay(nextMonthDate.getDate()));
            dispatch(setCurrentMonth(nextMonthDate.getMonth()));
            dispatch(setCurrentYear(nextMonthDate.getFullYear()));
        }

        if (page === "schedule") {
            dispatch(setScheduleCurrentDay(nextMonthDate.getDate()));
            dispatch(setScheduleCurrentMonth(nextMonthDate.getMonth()));
            dispatch(setScheduleCurrentYear(nextMonthDate.getFullYear()));
        }
    };

    const handlePrevDay = () => {
        const prevDayDate = new Date(currentDate);
        prevDayDate.setDate(prevDayDate.getDate() - 1);
        setFilterMonth(prevDayDate.getMonth());
        setFilterYear(prevDayDate.getFullYear());

        if (page === "calendar") {
            dispatch(setCurrentDay(prevDayDate.getDate()));
            dispatch(setCurrentMonth(prevDayDate.getMonth()));
            dispatch(setCurrentYear(prevDayDate.getFullYear()));
        }

        if (page === "schedule") {
            dispatch(setScheduleCurrentDay(prevDayDate.getDate()));
            dispatch(setScheduleCurrentMonth(prevDayDate.getMonth()));
            dispatch(setScheduleCurrentYear(prevDayDate.getFullYear()));
        }
    };

    /** begin week */
    const handlePrevWeek = () => {
        const prevDayDate = new Date(currentDate);
        prevDayDate.setDate(prevDayDate.getDate() - 7);
        setFilterMonth(prevDayDate.getMonth());
        setFilterYear(prevDayDate.getFullYear());

        if (page === "calendar") {
            dispatch(setCurrentDay(prevDayDate.getDate()));
            dispatch(setCurrentMonth(prevDayDate.getMonth()));
            dispatch(setCurrentYear(prevDayDate.getFullYear()));
        }
        if (page === "schedule") {
            dispatch(setScheduleCurrentDay(prevDayDate.getDate()));
            dispatch(setScheduleCurrentMonth(prevDayDate.getMonth()));
            dispatch(setScheduleCurrentYear(prevDayDate.getFullYear()));
        }
    };

    const handleNextWeek = () => {
        const prevDayDate = new Date(currentDate);
        prevDayDate.setDate(prevDayDate.getDate() + 7);
        setFilterMonth(prevDayDate.getMonth());
        setFilterYear(prevDayDate.getFullYear());

        if (page === "calendar") {
            dispatch(setCurrentDay(prevDayDate.getDate()));
            dispatch(setCurrentMonth(prevDayDate.getMonth()));
            dispatch(setCurrentYear(prevDayDate.getFullYear()));
        }
        if (page === "schedule") {
            dispatch(setScheduleCurrentDay(prevDayDate.getDate()));
            dispatch(setScheduleCurrentMonth(prevDayDate.getMonth()));
            dispatch(setScheduleCurrentYear(prevDayDate.getFullYear()));
        }
    };
    /** end week */

    const handleNextDay = () => {
        const nextDayDate = new Date(currentDate);
        nextDayDate.setDate(nextDayDate.getDate() + 1);
        setFilterMonth(nextDayDate.getMonth());
        setFilterYear(nextDayDate.getFullYear());

        if (page === "calendar") {
            dispatch(setCurrentDay(nextDayDate.getDate()));
            dispatch(setCurrentMonth(nextDayDate.getMonth()));
            dispatch(setCurrentYear(nextDayDate.getFullYear()));
        }

        if (page === "schedule") {
            dispatch(setScheduleCurrentDay(nextDayDate.getDate()));
            dispatch(setScheduleCurrentMonth(nextDayDate.getMonth()));
            dispatch(setScheduleCurrentYear(nextDayDate.getFullYear()));
        }
    };

    return (
        <div className={css.wrapper}>
            <DatePickerCustom
                page={page}
                filterMonth={filterMonth}
                setFilterMonth={setFilterMonth}
                filterYear={filterYear}
                setFilterYear={setFilterYear}
                handlePrevMonth={handlePrevMonth}
                handleNextMonth={handleNextMonth}
                changePeriod={changePeriod}
                periodType={periodType}
            />
            <div className={css.btnWrapper}>
                {periodType === "month" ? (
                    <button
                        className={`${css.paginatorBtn} ${css.paginatorBtnLeft}`}
                        type="button"
                        onClick={handlePrevMonth}
                        // disabled={
                        //   new Date().getMonth() === filterMonth &&
                        //   new Date().getFullYear() === filterYear
                        // }
                        // disabledStyle={
                        //   new Date().getMonth() === filterMonth &&
                        //   new Date().getFullYear() === filterYear
                        //     ? true
                        //     : false
                        // }
                    >
                        <ArrowLeft />
                    </button>
                ) : periodType === "week" ? (
                    <button
                        className={`${css.paginatorBtn} ${css.paginatorBtnLeft}`}
                        type="button"
                        onClick={handlePrevWeek}
                        // disabled={checkDate(currentDate)}
                        // disabledStyle={checkDate(currentDate)}
                    >
                        <ArrowLeft />
                    </button>
                ) : (
                    <button
                        className={`${css.paginatorBtn} ${css.paginatorBtnLeft}`}
                        type="button"
                        onClick={handlePrevDay}
                        // disabled={checkDate(currentDate)}
                        // disabledStyle={checkDate(currentDate)}
                    >
                        <ArrowLeft />
                    </button>
                )}
                {periodType === "month" ? (
                    <button className={`${css.paginatorBtn} ${css.paginatorBtnRight}`} type="button" onClick={handleNextMonth}>
                        <ArrowRight />
                    </button>
                ) : periodType === "week" ? (
                    <button
                        className={`${css.paginatorBtn} ${css.paginatorBtnRight}`}
                        type="button"
                        onClick={handleNextWeek}
                        // disabled={checkDate(currentDate)}
                        // disabledStyle={checkDate(currentDate)}
                    >
                        <ArrowRight />
                    </button>
                ) : (
                    <button className={`${css.paginatorBtn} ${css.paginatorBtnRight}`} type="button" onClick={handleNextDay}>
                        <ArrowRight />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PeriodPaginator;
