import { Field } from 'formik';
import {titles} from "../../constants";
import css from "./DynamicFieldForm.module.css"

// Компонент для текстового типа полей
const DynamicActiveFieldForm = ({field, form}) => {

    return (

        <label htmlFor="active" className={css.formLabel}>Введіть {titles[field]}
            <Field as="select" name="active" id="active" required className={css.formSelect}>
                <option value="true" className={css.formOption}> 
                    активно
                </option>
                <option value="false" className={css.formOption}> 
                    неактивно
                </option>
            </Field>
        </label>

    );
       
};

export default DynamicActiveFieldForm;