import React from "react";
import { Field } from "formik";
import { titles } from "../../constants";
import css from "./DynamicFieldForm.module.css";

// Компонент для текстового типа полей
const DynamicPasswordFieldForm = ({ field, form }) => {
    const pattern="^.{6,100}$";

    return (
        <label htmlFor={field} className={css.formLabel}>
            Введіть {titles[field]}
            <Field type="password" 
                name={field} 
                pattern={pattern} 
                title="Пароль повинен мати від 6 до 100 символів" 
                minLength="6"
                maxLength="100"
                autoComplete="off"
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                required
                className={css.formInput} 
            />
        </label>
    );
};

export default DynamicPasswordFieldForm;