import { Route, Routes, useSearchParams } from "react-router-dom";
import { useEffect, useState, lazy } from "react";
import { useDispatch } from "react-redux";
import { RestrictedRoute } from "../Routes/RestrictedRoute";
import { PrivateRoute } from "../Routes/PrivateRoute";
import MainLayout from "../layout/MainLayout/MainLayout";
import { refreshUser } from "../redux/auth/authOperations";
import { setToken } from "../redux/auth/authSlice";
import { useAuth } from "../hooks/useAuth";
import Loader from "../ui/Loader/Loader";
import MainPage from "../pages/MainPage/MainPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import CalendarPage from "../pages/CalendarPage/CalendarPage";
import AddAppointmentPage from "../pages/AddAppointmentPage/AddAppointmentPage";
import EditAppointmentPage from "../pages/EditAppointmentPage/EditAppointmentPage";
import SchedulePage from "../pages/SchedulePage/SchedulePage";
import AddSchedulePage from "../pages/AddSchedulePage/AddSchedulePage";
import AdminNavigationPage from "../pages/AdminNavigationPage/AdminNavigationPage";
import AdminPage from "../pages/AdminPage/AdminPage";
import NotFound from "../pages/NotFound/NotFound";
import AddDirectoryPage from "../pages/AddDirectoryPage/AddDirectoryPage";
import EditDirectoryPage from "../pages/EditDirectoryPage/EditDirectoryPage";

// const MainPage = lazy(() => import('../pages/MainPage/MainPage'));
// const LoginPage = lazy(() => import('../pages/LoginPage/LoginPage'));
// const CalendarPage = lazy(() => import('../pages/CalendarPage/CalendarPage'));
// const NotFound  = lazy(() => import('../pages/NotFound/NotFound'));
const ChoosedDay = lazy(() => import("./ChoosedDay/ChoosedDay"));
const ChoosedMonth = lazy(() => import("./ChoosedMonth/ChoosedMonth"));
const ChoosedWeek = lazy(() => import("./ChoosedWeek/ChoosedWeek"));
// const AddAppointmentPage = lazy(() => import("../pages/AddAppointmentPage/AddAppointmentPage"));
// const EditAppointmentPage = lazy(() => import("../pages/EditAppointmentPage/EditAppointmentPage"));
const AppointmentDetailsPage = lazy(() => import("../pages/AppointmentDetailsPage/AppointmentDetailsPage"));
// const SchedulePage = lazy(() => import("../pages/SchedulePage/SchedulePage"));
// const AddSchedulePage = lazy(() => import("../pages/AddSchedulePage/AddSchedulePage"));
// const AdminNavigationPage = lazy(() => import("../pages/AdminNavigationPage/AdminNavigationPage"));
// const AdminPage = lazy(() => import( "../pages/AdminPage/AdminPage"));
// const AddDirectoryPage = lazy(() => import("../pages/AddDirectoryPage/AddDirectoryPage"));
// const EditDirectoryPage = lazy(() => import("../pages/EditDirectoryPage/EditDirectoryPage"));

function App() {
    const [pageName, setPageName] = useState(null);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const { isRefreshing } = useAuth();
    const dispatch = useDispatch();

    const updatePageName = (name) => {
        setPageName(name);
    };

    useEffect(() => {
        if (token) {
            dispatch(setToken(token));
        }
        dispatch(refreshUser());
    }, [dispatch, token]);

    return (
        <>
            {isRefreshing ? (
                <Loader />
            ) : (
                <Routes>
                    <Route path="/">
                        <Route index element={<RestrictedRoute redirectTo="calendar" component={<MainPage />} />} />
                        <Route path="login" element={<RestrictedRoute redirectTo={"/calendar"} component={<LoginPage />} />} />

                        <Route path="/" element={<PrivateRoute redirectTo="/" component={<MainLayout pageName={pageName} />} />}>
                            <Route path="calendar" element={<PrivateRoute redirectTo="/" component={<CalendarPage updatePageName={updatePageName} />} />}>
                                <Route path="month/:currentDate" element={<PrivateRoute component={<ChoosedMonth page="calendar" />} />} />
                                <Route path="week/:currentWeek" element={<PrivateRoute component={<ChoosedWeek page="calendar" />} />} />
                                <Route path="day/:currentDay" element={<PrivateRoute component={<ChoosedDay page="calendar" />} />} />
                            </Route>
                            <Route path="add_appointment" element={<PrivateRoute redirectTo="/" component={<AddAppointmentPage updatePageName={updatePageName} />} />} />
                            <Route path="appointment/:appointmentId" element={<PrivateRoute component={<AppointmentDetailsPage updatePageName={updatePageName} />} />} />
                            <Route path="edit_appointment" element={<PrivateRoute redirectTo="/" component={<EditAppointmentPage updatePageName={updatePageName} />} />} />
                            {/* schedule */}
                            <Route path="schedule" element={<PrivateRoute redirectTo="/" component={<SchedulePage updatePageName={updatePageName} />} />}>
                                <Route path="month/:currentDate" element={<PrivateRoute component={<ChoosedMonth page="schedule" />} />} />
                                <Route path="week/:currentWeek" element={<PrivateRoute component={<ChoosedWeek page="schedule" />} />} />
                                <Route path="day/:currentDay" element={<PrivateRoute component={<ChoosedDay page="schedule" />} />} />
                            </Route>
                            {/* schedule */}
                            <Route path="add_schedule" element={<PrivateRoute redirectTo="/" component={<AddSchedulePage updatePageName={updatePageName} />} />} />
                            <Route path="admin_nav" element={<PrivateRoute redirectTo="/" component={<AdminNavigationPage updatePageName={updatePageName} />} />} />
                            <Route path="admin" element={<PrivateRoute redirectTo="admin_nav" component={<AdminPage updatePageName={updatePageName} />} />} />
                            <Route path="add_directory" element={<PrivateRoute redirectTo="/" component={<AddDirectoryPage updatePageName={updatePageName} />} />} />
                            <Route path="edit_directory" element={<PrivateRoute redirectTo="/" component={<EditDirectoryPage updatePageName={updatePageName} />} />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            )}
        </>
    );
}

export default App;
