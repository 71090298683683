import React, { useState, Suspense, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import MessageNotification from "../../components/MessageNotification/MessageNotification";
import CalendarToolbar from "../../components/CalendarToolbar/CalendarToolbar";
import { selectDay, selectMonth, selectYear, selectWeek } from "../../redux/appointments/selectors";
import Loader from "../../ui/Loader/Loader";
import { getAppointments } from "../../redux/appointments/operations";

const CalendarPage = () => {
    // console.log("CalendarPage");
    const navigate = useNavigate();
    const month = useSelector(selectMonth);
    const day = useSelector(selectDay);
    const year = useSelector(selectYear);
    const week = useSelector(selectWeek);

    const dispatch = useDispatch();

    const { pathname } = useLocation();
    const initialPeriodType = pathname.includes("/calendar/day") ? "day" : pathname.includes("/calendar/week") ? "week" : "month";

    //  console.log("initialPeriodType=", initialPeriodType);

    // useEffect(() => {
    //     updatePageName("Calendar");
    // }, [updatePageName]);

    const [periodType, setPeriodType] = useState(initialPeriodType || "month");
    const [currentDateMonth] = useState(month);
    const [currentDateYear] = useState(year);

    useEffect(() => {

        const fetchData = async () => {
            let message;
            try {
                if (periodType === "month") {
                    await dispatch(getAppointments("month"))
                    .then(res=>{
                      
                        if(res.payload.status===200){
                            navigate(`/calendar/month/${month}`);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);

                    });
                
                }
                if (periodType === "week") {
                    await dispatch(getAppointments("week"))
                    .then(res=>{
                       
                        if(res.payload.status===200){
                            navigate(`/calendar/week/${week}`);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);

                    });
                }
                
                if (periodType === "day") {
                    await dispatch(getAppointments("day"))
                    .then(res=>{
                       
                        if(res.payload.status===200){
                            navigate(`/calendar/day/${day}`);
                        }else if(res.error.message==="Rejected"){
                            message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                            MessageNotification("error", message);

                        }
                    })
                    .catch(error => {
                        message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                        MessageNotification("error", message);

                    });
                }
            } catch (error) {
                message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                MessageNotification("error", message);
            }
        };

        fetchData();
    }, [navigate, periodType, day, month, week, dispatch]);

    const checkDate = (date) => {
        const currentDate = new Date(currentDateYear, currentDateMonth, 2);
        if (date > currentDate) {
            return false;
        }
        return true;
    };

    return (
        <>
            <Toaster/>
            <h2 className="caption">
                <center>Календар прийомів</center>
            </h2>
            <CalendarToolbar periodType={periodType} changePeriod={setPeriodType} checkDate={checkDate} page={"calendar"} />
            <Suspense fallback={<Loader type={"suspense"} />}>
                <Outlet context={[setPeriodType, checkDate]} />
            </Suspense>
        </>
    );
};

export default CalendarPage;
