import ScheduleForm from "../../components/ScheduleForm/ScheduleForm";


const AddSchedulePage=()=>{


    return (
        <>
        <ScheduleForm type="add" />
        </>
   
    );

}
export default AddSchedulePage