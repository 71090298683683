import Modal from "../../ui/Modal/Modal";
import ButClose from "../../ui/buttons/ButClose/ButClose";
import ModalDelete from "../ModalDelete/ModalDelete";
import ModalAddDoctor from "../ModalAddDoctor/ModalAddDoctor";
import css from "./AppointmentModal.module.css";

const AppointmentModal = ({onClose, modalOption, currentItem, date, time, text, doctors, type})=>{

    return (
        <Modal onClose={onClose}>
            <div className={css.butWrapper}> 
                <ButClose onClose={onClose}/>
            </div>
            {modalOption==="delete" ? 
            (<ModalDelete onClose={onClose} currentItem={currentItem} text={text} type={type}/>) :
            (<ModalAddDoctor  onClose={onClose} currentItem={currentItem}  date={date} time={time} text={text} doctors={doctors} type={type}/>)
            }
        </Modal>

    );
}


export default AppointmentModal;

