import { instance } from "./instance";

export const apiGetDoctors = async () => {
    try{     
        const result = await instance.get(`/api/doctors`);
        return result;
    }catch (error){
        throw new Error(error.message);
    }
}

export const apiAddDoctor = async (doctor) => {
    try {
        const result = await instance.post(`/api/doctors`, doctor);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiUpdateDoctor = async (doctor, id) => {
    try {
        const result = await instance.put(`/api/doctors/${id}`, doctor);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiDeleteDoctor = async (id) => {
    try {
        const result = await instance.delete(`/api/doctors/${id}`);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};