import { instance } from "./instance";

export const apiGetWorkhours = async () => {
    try{     
        const result = await instance.get(`/api/workhours`);
        return result;
    }catch (error){
        throw new Error(error.message);
    }
}

export const apiAddWorkhour = async (workhour) => {
    try {
        const result = await instance.post(`/api/workhours`, workhour);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiUpdateWorkhour = async (workhour, id) => {
    try {
        const result = await instance.put(`/api/workhours/${id}`, workhour);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiDeleteWorkhour = async (id) => {
    try {
        const result = await instance.delete(`/api/workhours/${id}`);
        return result;
    } catch (error) {
        throw new Error(error.message);
    }
};