import { createSlice } from "@reduxjs/toolkit";
import { createAppointment, deleteAppointment, getAppointments, updateAppointment } from "./operations";
import { logOutUser } from "../auth/authOperations";
import { getDate, getYear, getWeek } from "date-fns";

export const initialState = {
    month: new Date().getMonth(),
    day: getDate(new Date()),
    year: getYear(new Date()),
    week: getWeek(new Date()),
    // choosedDate: new Date().toISOString(),
    items: [],
    isLoadingAppointments: false,
    error: null,
    status: null

};

export const handlePending = (state, action) => {
    state.isLoadingAppointments = true;
    state.status="pending";
    console.log("handlePending Appointment")
    console.log("handlePending action.payload=", action.payload)

};

export const handleRejected = (state, action) => {
    state.isLoadingAppointments = false;
    state.error = action.payload;
    state.status="rejected";
    console.log("handleRejected Appointment");
    console.log("handleRejected action.payload=", action.payload)
    console.log('handleRejected Error message:', action.error.message);
    console.log("handleRejected state.error=", state.error)
    console.log("handleRejected state.status=", state.status)
};

export const handleFulfilled = (state, action) => {
    state.items = action.payload.data;
    state.isLoadingAppointments = false;
    state.error = null;
    state.status=action.payload.status;
    console.log("handleFulfilled Appointment")
    console.log("handleFulfilled action.payload=", action.payload)
    console.log("handleFulfilled state.items=", state.items)
    console.log("handleFulfilled state.error=", state.error)
    console.log("handleFulfilled state.status=", state.status)
};


export const handleCreateFulfilled = (state, action) => {
    console.log("handleCreateFulfilled action.payload=", action.payload)
    console.log("state.items=", state.items)
    if(action.payload){
        state.items.push(action.payload.data);
        state.isLoadingAppointments = false;
        state.error = null;
        // state.status="create fulfilled";
        state.status=action.payload.status;
    }
    console.log("handleCreateFulfilled")
    console.log("handleCreateFulfilled state.items=", state.items)
    console.log("handleCreateFulfilled state.error=", state.error)
    console.log("handleCreateFulfilled state.status=", state.status)
};


export const handleUpdateFulfilled = (state, action) => {
    console.log("state.items=", state.items)
    console.log("handleUpdateFulfilled action.payload=", action.payload)
    const oldItemIndex = state.items.findIndex((appointment) => appointment.appointment_id === action.payload.data.appointment_id);
    state.items.splice(oldItemIndex, 1, action.payload.data);
    console.log("state.items=", state.items)
    state.error = null;
    // state.status="update fulfilled";
    state.status=action.payload.status;
    console.log("handleUpdateFulfilled state.status=", state.status)
    console.log("handleUpdateFulfilled state.error=", state.error)
};

export const handleDeleteFulfilled = (state, action) => {
    console.log("handleDeleteFulfilled action.payload=", action.payload)
    const index = state.items.findIndex(item => item.appointment_id === action.payload.data.appointment_id);
    state.items.splice(index, 1);
    state.isLoadingAppointments = false;
    state.error = null;
    // state.status="delete fulfilled";
    state.status=action.payload.status;
    console.log("handleDeleteFulfilled state.status=", state.status)
    console.log("handleDeleteFulfilled state.error=", state.error)
    console.log("handleDeleteFulfilled state.items=", state.items)
};

const handleLogOutFulfilled = (state)=>{
    state.items = [];
    state.error = null;
    state.status="fulfilled";
    state.isLoadingAppointments = false;
};

const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
        setCurrentDay(state, action) {
            state.day = action.payload;
        },
        setCurrentMonth(state, action) {
            state.month = action.payload;
        },
        setCurrentYear(state, action) {
            state.year = action.payload;
        },
        // setChoosedDate(state, action) {
        //   state.choosedDate = action.payload;
        // },

        /** begin week */
        setAppointmentsCurrentWeek(state, action) {
            state.scheduleWeek = action.payload;
        },
        /** end week */
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAppointments.pending, handlePending)
            .addCase(getAppointments.fulfilled, handleFulfilled)
            .addCase(getAppointments.rejected, handleRejected)
            .addCase(createAppointment.pending,  handlePending)
            .addCase(createAppointment.fulfilled, handleCreateFulfilled)
            .addCase(createAppointment.rejected, handleRejected)
            .addCase(updateAppointment.pending, handlePending)
            .addCase(updateAppointment.fulfilled, handleUpdateFulfilled)
            .addCase(updateAppointment.rejected, handleRejected)
            .addCase(deleteAppointment.pending, handlePending)
            .addCase(deleteAppointment.fulfilled, handleDeleteFulfilled)
            .addCase(deleteAppointment.rejected, handleRejected)
            .addCase(logOutUser.fulfilled, handleLogOutFulfilled);
    },
});

export const { setCurrentDay, setCurrentMonth, setCurrentYear, setAppointmentsCurrentWeek } = appointmentsSlice.actions;

export const appointmentsReducer = appointmentsSlice.reducer;
