import {Tooltip} from 'react-tooltip';
import { Link } from 'react-router-dom';
import sprite from "../../../assets/icons/icons.svg";
import css from "./LinkWithIcon.module.css"

const LinkWithIcon = ({src, state, tooltipId, tooltipContent, icon, size})=>{


    return(
        <Link 
            to={src}
            state={state} 
            data-tooltip-id={tooltipId}
            data-tooltip-content={tooltipContent}
            className={css.toolButton}

        >
            <svg className={css[size]}>
                <use href={`${sprite}#${icon}`}></use>
            </svg>  

            <Tooltip id={tooltipId} place="bottom" style={{ backgroundColor: "#rgba(220, 227, 229, 0.50)", borderRadius: "8px", color: "#FFFFFF"}}/>
        </Link>

    );

}

export default LinkWithIcon;