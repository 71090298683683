import { NavLink } from "react-router-dom";
import styled from "styled-components";
import navigation from "../../data/navigation.json";
import sprite from "../../assets/icons/icons.svg";
import "../../styles/variables.css";
import css from "./Navigation.module.css";

const StyledLink = styled(NavLink)`
    &.active {
        color: var(--accent-color);
        background-color: #e3f3ff;
    }
`;

const Navigation = ({ onClose }) => {
    return (
        <nav className={css.menu}>
            {navigation.map(({ id, src, icon, text }) => (
                <StyledLink to={src} className={css.link} key={id} onClick={onClose}>
                    <svg className={css.icon}>
                        <use href={`${sprite}${icon}`}></use>
                    </svg>
                    {text}
                </StyledLink>
            ))}
        </nav>
    );
};

export default Navigation;
