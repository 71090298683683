import { Suspense, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Header } from "../Header/Header";
import SideBar from "../SideBar/SideBar";

import TabBar from "../TabBar/TabBar";

import Loader from "../../ui/Loader/Loader";
import css from "./MainLayout.module.css";

const MainLayout = ({ pageName }) => {
    const [isOpenSidebar, setIsOpenSidebar] = useState(true);
    const windowWidth = window.innerWidth;
    const openSidebarBtnRef = useRef(null);
    // console.log("openSidebarBtnRef=", openSidebarBtnRef)

    useEffect(() => {
        if (windowWidth < 1200) {
            setIsOpenSidebar(false);
        }
    }, [windowWidth]);

    const openSidebar = () => {
        setIsOpenSidebar(true);
    };

    const onCloseSidebar = () => {
        setIsOpenSidebar(false);
    };

    return (
        <>
            <div className={css.wrapper}>
                {/* <AnimatePresence>{isOpenSidebar && <SideBar openBtnRef={openSidebarBtnRef} onClose={onCloseSidebar} />}</AnimatePresence> */}
                <div className={css.mainContent}>
                    <Header ref={openSidebarBtnRef} openMethod={openSidebar} title={pageName} isOpenSidebar={isOpenSidebar} />

                    <div className={css.outletWrapper}>
                        <Suspense fallback={<Loader type={"suspense"} />}>
                            <Outlet />
                        </Suspense>
                    </div>
                    <TabBar />
                </div>
            </div>
        </>
    );
};

export default MainLayout;
