import { createAsyncThunk } from "@reduxjs/toolkit";
import { logIn, logOut, register } from "../../api/auth";
import { refresh } from "../../api/users";

export const logInUser = createAsyncThunk("auth/login", async (credentials, thunkAPI) => {
    try {
        console.log("operations ", credentials);
        const data = await logIn(credentials);
        // console.log(data);
        // const res = {
        //     data: {
        //         user: {
        //             login: data.login,
        //             avatarURL: data.avatar,
        //         },
        //         token: data.token,
        //     },
        // };
        // const res = {
        //     data: {
        //         user: {
        //             name: "client",
        //             avatarURL: "",
        //         },
        //         // token: data.token,
        //     },
        // };

        // console.log("res=", res.data);
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const logOutUser = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
    // console.log("logOut");
    try {
        await logOut();
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const refreshUser = createAsyncThunk("auth/refresh", async (_, thunkAPI) => {
    //** Reading the token from the state via getState() */
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;
    // console.log("persistedToken", persistedToken);
    if (persistedToken === null) {
        // If there is no token, exit without performing any request
        return thunkAPI.rejectWithValue("Unable to fetch user");
    }
    try {
        // If there is a token, add it to the HTTP header and perform the request
        // setAuthHeader(persistedToken);
        const res = await refresh(persistedToken);
        // console.log(res);
        return res;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});


export const registerUser = createAsyncThunk("auth/register", async (user, thunkAPI) => {
    console.log("registerUser ");
    try {
        const res = await register(user);
        return res;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});