import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import sprite from "../../../assets/icons/icons.svg";
import css from "./LinkGoBack.module.css";
// import style from "../LinkBut.module.css";

const LinkGoBack = () => {
    const location = useLocation();
    const backLinkLocationRef = useRef(location.state?.from ?? '/');
    console.log("location.state?.from=",  location.state?.from)
    console.log("backLinkLocationRef=",  backLinkLocationRef)

    return (
        <div className={css.wrapper}>
            <Link to={backLinkLocationRef.current} className={css.link}>
                <svg className={css.icon}>
                    <use href={`${sprite}#icon-arrow-left`}></use>
                </svg>
            </Link>
            {/* <button onClick={handleGoBack}>Назад</button> */}
        </div>
    );
};

export default LinkGoBack;
