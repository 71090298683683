
const NotFound=()=>{

    return(
        <>
            <h1>Not Found Page</h1>
        </>
        
        
        );

}

export default NotFound;