import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import MessageNotification from "../MessageNotification/MessageNotification";
import * as schedulesAPI from "../../api/schedules";
import * as appointmentsAPI from "../../api/appointments";
import isRecord from "../../services/isRecord";
import DirectoryToolbar from '../DirectoryToolbar/DirectoryToolbar';
import LinkWithIcon from '../../ui/LinkButtons/LinkWithIcon/LinkWithIcon';
import {getDirectories} from "../../redux/directories/directoriesOperations";
import {useDirectory} from "../../hooks/useDirectory";
import {titles, excludes} from "../../constants";
import css from "./Directories.module.css";

const Directories =()=>{
    const location = useLocation();
    const dispatch = useDispatch();

    const {directory}=useDirectory();
    // console.log("directory", directory)
    // console.log("location.state.item=", location.state.item)

    const [appointments, setAppointments]=useState([]);
    const [schedules, setSchedules]=useState([]);
    

    useEffect(() => {
        dispatch(getDirectories(location.state.item));
                  
     },[location.state.item, dispatch]);

     useEffect(() => {
        
        const getAllSchedules=async()  => {
            try{
                const schedules=await schedulesAPI.apiGetSchedules();
                // console.log("Directories schedules=", schedules)
                setSchedules(schedules.data); 
            }catch(error){
                const message="Directories Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                MessageNotification("error", message);
            }

        };
        const getAllAppointments=async()=>{
            try{
                const appointments=await appointmentsAPI.apiGetAppointments();
                // console.log("Directories appointments.data=", appointments.data)
                setAppointments(appointments.data); 
            }catch(error){
                const message="Directories Ой! Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n"+error.toString();
                MessageNotification("error", message);
            }
        }

        getAllAppointments();
        getAllSchedules();

      }, []);

    const handleCanChange=(item)=>{
        // console.log("handleCanChange item=", item)
        let isCanChange=false;
        // console.log('handleCanChange location.state.item=', location.state.item);
        // console.log('handleCanChange appointments.length=', appointments.length);
        if(location.state.item==="services" && appointments.length>0){
            // console.log('handleCanChange location.state.item==="services" && appointments.length>0');
            isCanChange=!(isRecord(item, location.state.item, appointments));
        }

        if(location.state.item!=="services" && schedules.length>0){
            isCanChange=!(isRecord(item, location.state.item, schedules));
        }
        // console.log("isCanChange=", isCanChange)
 
        return isCanChange;


    }
   
    return(
        <>  
        <div>
            <Toaster />
            <h2><center>{location.state.item}</center></h2>
            <div className={css.butWrapper}>
                <LinkWithIcon  
                    src={`../../add_directory`}
                    state={{ from: location, item: location.state.item}}
                    icon="icon-plus-circle"
                    tooltipId="tooltip-add"
                    tooltipContent="Додати"
                    size="big"
                />
            </div>
          
            <ul>
                {directory?.map((item, index) => {
          
                    return(
                        <li key={index} className={css.item}>
                            <div className={css.wrapper}>  
                                <div>                                
                                       
                                {Object.entries(item).map(([key, value], i) => {
                                    let formattedValue=value;
       
                                    if(key==="time"){
                                        formattedValue=value.slice(0,5);
                                    }

                                    if(key==="active"){
                                        if(item.active){
                                            formattedValue="активно";
                                        }else{
                                            formattedValue="неактивно";
                                        }
                                    }

                                    // if(key.includes('id')|| key.includes('user')|| key.includes('createdAt') || key.includes('updatedAt')){
                                    if(excludes.includes(key)){
                                        return null;
                                    }

                                    if(key==="avatar"){
                                        return(
                                            
                                            <div key={i} className={css.textWrapper}>
                                                <span className={css.textTitle}>{titles[key]}:</span>
                                                <div key={i} className={css.userPhotoWrapper}>    
                                                    <img src={formattedValue} alt="user avatar" className={css.userPhoto} />                                           
                                                </div>
                                            </div>
                                        )


                                    }
  
                                    return( 
                                        <div key={i} className={css.textWrapper}>
                                            <span className={css.textTitle}>{titles[key]}:</span>
                                            <span className={css.text}>{formattedValue}</span> 
                                        </div>
                                    )
                                
                                })}
                                </div>   
                            <DirectoryToolbar record={item} isCanChange={handleCanChange(item)}/>
                            
                            </div>
                        </li>

                )

                })}
            </ul>
        </div>
        </>
    );

}
export default Directories;