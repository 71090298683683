import Button from "../Button/Button";
import { FiLogIn } from "react-icons/fi";

const ButLogin = ({ type, className }) => {
    return (
        <Button type={type} className={className}>
            Увійти <FiLogIn />
        </Button>
    );
};

export default ButLogin;
