import NameApp from "../../components/NameApp/NameApp";
import LinkLogin from "../../ui/LinkButtons/LinkLogin/LinkLogin";
import Logo from "../../ui/Logo/Logo";
import css from "./MainPage.module.css";

const MainPage = () => {
    return (
        <>
            <section className={css.hero}>
                <h2 className={css.title}>Вітаємо!</h2>
                <div className={css.wrapper}>
                    <Logo />
                    <NameApp />
                </div>
                <LinkLogin />
            </section>
        </>
    );
};

export default MainPage;
