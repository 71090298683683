const getDoctorsFromRecords= (records)  => {
    const uniqDoctorsArray=[];

    for (const record of records) {
        const doctor={
            doctor_id: record?.Doctor?.doctor_id,
            surname: record?.Doctor?.surname,
            name: record?.Doctor?.name,
        }
        let isDoc=false;
        for (const doc of uniqDoctorsArray) {
            if(doc.doctor_id===doctor.doctor_id){
                isDoc=true;
                break;
            }
        }
        if(!isDoc){
            uniqDoctorsArray.push(doctor)
        }

    }

    return uniqDoctorsArray;
                
};

export default getDoctorsFromRecords;