import { instance } from "./instance";

export const apiGetColumnsTable = async (tableName) => {

    try {
        const { data } = await instance.get(`/api/columns/${tableName}`);
        return data;
    } catch (error) {
        throw new Error(error.message);
    }
};