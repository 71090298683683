import React, { useState, useRef } from 'react';
import { Field } from "formik";
import { titles } from "../../constants";
import Avatar from "../Avatar/Avatar";
import ButWithIcon from "../../ui/buttons/ButWithIcon/ButWithIcon";
import MessageNotification from "../MessageNotification/MessageNotification";
import css from "./DynamicFieldForm.module.css";
import "../../styles/variables.css";


const DynamicAvatarFieldForm = ({ field, form, setFieldValue, avatarURL }) => {
    const fileInputRef = useRef(null); // используем useRef для доступа к input
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        console.log("file=", file)
        if (file) {
          const maxSizeInBytes = 600 * 1024;
          if (file.size > maxSizeInBytes) {
            MessageNotification("error", "The uploaded file is too large. The maximum size is 600 KB.")   
            e.target.value = null;
            return false;
          }
        }
        let reader = new FileReader();
        reader.onloadend = function (e) {
          setSelectedFile(reader.result)
        }
        reader.readAsDataURL(file)
        return true;
      }

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Открываем окно для выбора файла, если элемент существует
        }
    };

    return (
        <div className={css.container}>
          <p className={css.formLabel}>
          Завантажити аватар
          </p>
  
          <div className={css.avatarWrapper}>
            <Avatar avatarURL={selectedFile ? selectedFile :avatarURL}/>
              <input 
                id={field} 
                name={field} 
                type="file"
                ref={fileInputRef}
                accept="image/*,.jpeg,.jpg,.png"
                style={{ display: 'none' }}
                onChange={e => {
                    const isFile = handleFileChange(e);
                    isFile && setFieldValue("avatar", e.target.files[0])
                  }
                }
              />
              <div className={css.butWrapper}>
                <ButWithIcon 
                    tooltipId="tooltip-add"
                    tooltipContent="Додати файл"
                    icon="icon-plus"
                    onClick={handleClick}
                    backgroundColorVar="--first-main-color"
                    colorVar="--secondary-color"
                    borderRadius="50%"
                />
              </div>
          </div>
            
            

        </div>
        
    );
};

export default DynamicAvatarFieldForm;