import { useSelector } from "react-redux";
import {
    selectDirectories,
    selectIsLoadingDirectories,
    selectError

} from "../redux/directories/directoriesSelectors";

export const useDirectory = () => {
    const directory = useSelector(selectDirectories);
    const isLoadingDirectories = useSelector(selectIsLoadingDirectories);
    const directoriesError=useSelector(selectError);

    return {
        directory,
        isLoadingDirectories,
        directoriesError
    };
};
