import LinkGoBack from "../../ui/LinkButtons/LinkGoBack/LinkGoBack";
import AppointmentForm from "../../components/AppointmentForm/AppointmentForm";

const EditAppointmentPage=()=>{


    return (
        <>
            <LinkGoBack/>
            <AppointmentForm type="edit"/>  
        </>
    );

}
export default EditAppointmentPage;