import React from "react";

import sprite from "../../assets/icons/icons.svg";
import { Link } from "react-router-dom";

const Tab = ({ name, icon, isActive, onClick, src }) => {
    // console.log(icon);

    return (
        <li className={`${name} ${isActive ? "active" : ""}`} data-where={name} onClick={() => onClick(name)}>
            <Link to={src}>
                <span
                // className="material-icons-outlined"
                >
                    <svg
                        style={{ width: 30, height: 30 }}
                        // className={css.icon}
                    >
                        <use href={`${sprite}${icon}`}></use>
                    </svg>
                    {/* {icon} */}
                </span>
            </Link>
        </li>
    );
};

export default Tab;
