import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import style from "../LinkBut.module.css";

const LinkLogin = () => {
    return (
        <Link to="/login" className={style.link}>
            Увійти <FiLogIn />
        </Link>
    );
};

export default LinkLogin;
