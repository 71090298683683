import React, { useState } from "react";
import Tab from "./Tab";
import navigation from "../../data/navigation.json";

import sprite from "../../assets/icons/icons.svg";
import { useAuth } from "../../hooks/useAuth";

// const tabsData = [
//     { name: "hello", icon: "home" },
//     { name: "products", icon: "shopping_bag" },
//     { name: "services", icon: "plumbing" },
//     { name: "about", icon: "business" },
//     { name: "help", icon: "help_outline" },
//     // { name: "user", icon: "<svg className={css.icon}><use href={`${sprite}${icon}`}></use></svg>",
// ];

// const tabsData = [
//     // {
//     //     id: 1,
//     //     src: "/",
//     //     // text: "Мій акаунт",
//     //     text: "Акаунт",
//     //     icon: "#icon-doctor",
//     //     name: "account",
//     // },
//     {
//         id: 2,
//         src: "schedule",
//         text: "Розклад",
//         icon: "#icon-schedule",
//         name: "schedule",
//     },
//     {
//         id: 3,
//         src: "calendar",
//         text: "Календар",
//         icon: "#icon-calendar",
//         name: "calendar",
//     },
//     {
//         id: 4,
//         src: "add_schedule",
//         text: "Запис у графік",
//         icon: "#icon-deadline",
//         name: "add_schedule",
//     },
//     {
//         id: 5,
//         src: "add_appointment",
//         text: "Запис на прийом",
//         icon: "#icon-doctor-appointment",
//         name: "add_appointment",
//     },
//     {
//         id: 6,
//         src: "admin_nav",
//         // text: "Панель Адміністратора",
//         text: "Адмін",
//         icon: "#icon-user-admin",
//         name: "admin",
//     },
// ];

// const tabsData = navigation;
let tabsData = [];
const TabBar = () => {
    const { user } = useAuth();
    if (user.role === "admin") {
        tabsData = [
            // {
            //     id: 1,
            //     src: "/",
            //     // text: "Мій акаунт",
            //     text: "Акаунт",
            //     icon: "#icon-doctor",
            //     name: "account",
            // },
            {
                id: 2,
                src: "schedule",
                text: "Розклад",
                icon: "#icon-schedule",
                name: "schedule",
            },
            {
                id: 3,
                src: "calendar",
                text: "Календар",
                icon: "#icon-calendar",
                name: "calendar",
            },
            {
                id: 4,
                src: "add_schedule",
                text: "Запис у графік",
                icon: "#icon-deadline",
                name: "add_schedule",
            },
            {
                id: 5,
                src: "add_appointment",
                text: "Запис на прийом",
                icon: "#icon-doctor-appointment",
                name: "add_appointment",
            },
            {
                id: 6,
                src: "admin_nav",
                // text: "Панель Адміністратора",
                text: "Адмін",
                icon: "#icon-user-admin",
                name: "admin",
            },
        ];
    } else {
        tabsData = [
            // {
            //     id: 1,
            //     src: "/",
            //     // text: "Мій акаунт",
            //     text: "Акаунт",
            //     icon: "#icon-doctor",
            //     name: "account",
            // },
            {
                id: 2,
                src: "schedule",
                text: "Розклад",
                icon: "#icon-schedule",
                name: "schedule",
            },
            {
                id: 3,
                src: "calendar",
                text: "Календар",
                icon: "#icon-calendar",
                name: "calendar",
            },
            {
                id: 4,
                src: "add_schedule",
                text: "Запис у графік",
                icon: "#icon-deadline",
                name: "add_schedule",
            },
            {
                id: 5,
                src: "add_appointment",
                text: "Запис на прийом",
                icon: "#icon-doctor-appointment",
                name: "add_appointment",
            },
        ];
    }
    const [activeTab, setActiveTab] = useState("calendar");

    const handleTabClick = (name) => {
        setActiveTab(name);
    };

    return (
        <div
            // className={`container ${activeTab}-style`}
            className={` ${activeTab}-style tabbar-position`}
        >
            <div className="tabbar tab-style1">
                <ul className="tabbar-list">
                    {tabsData.map((tab) => (
                        <Tab
                            key={tab.text}
                            name={tab.name}
                            icon={
                                tab.icon
                                // <svg
                                //     style={{ width: 30 }}
                                //     // className={css.icon}
                                // >
                                //     <use href={`${sprite}${tab.icon}`}></use>
                                // </svg>
                            }
                            isActive={activeTab === tab.name}
                            onClick={handleTabClick}
                            src={tab.src}
                        />
                    ))}
                    {/* <li className="follow">&nbsp;</li> */}
                </ul>
            </div>
        </div>
    );
};

export default TabBar;
