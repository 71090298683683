import { instance } from "./instance";

export const apiGetSchedules = async (paramsString) => {
    const searchParams = new URLSearchParams(paramsString);
    try {
        const response = await instance.get(`/api/schedules?${searchParams}`);
        return response;

    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiDeleteSchedule = async (id) => {
    try {
        const response = await instance.delete(`/api/schedules/${id}`);
        return response;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const apiAddSchedule = async (schedule) => {
    try {
        const response = await instance.post(`/api/schedules`, schedule);
        console.log("apiAddSchedule response=", response)
        return response;
    } catch (error) {
        console.log("apiAddSchedule error=", error)
        throw new Error(error.message);
    }
};