const getDateEnd=(month, year)=>{
    const fullMonth=(month+1).toString().padStart(2, '0')
    let day=31;

    const rangeMonth=['04','06','09','11'];

    //определяем високосный ли год
    let isLeapYear=true;
    if (year%4!==0){
        isLeapYear=false;
    }
    else{
        if(year%400!==0 && year%100===0){
            isLeapYear=false;
        } 
    }
       
    if(rangeMonth.includes(month)){
        day=30;
    }
    if(isLeapYear && month==='02'){
        day=29;
    }
    if(!isLeapYear && month==='02'){
        day=28;
    }

    const dateEnd=`${year}-${fullMonth}-${day}`
    return dateEnd;
}

export default getDateEnd;