import DirectoryForm from "../../components/DirectoryForm/DirectoryForm";

const EditDirectoryPage=()=>{


    return (
        <>
        <DirectoryForm type="edit"/>
        </>
    );

}
export default EditDirectoryPage