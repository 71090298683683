export const baseURL = "https://calendar.eglamed.com.ua"; //** !!!  для ХОСТИНГУ !!! */
// export const baseURL = "https://dev.calendar.eglamed.com.ua"; //** !!!  для ХОСТИНГУ (розробка) !!! */

// export const baseURL = "http://localhost:4000"; //** для локалхосту */
// export const baseURL = "http://192.168.128.101:4000"; //** для WAN на локалхості (mobile devices)*/

export const titles = {
    time: "Час прийому",
    active: "Статус активності",
    description: "Опис послуги",
    price: "Ціна",
    name: "Ім'я",
    surname: "Прізвище",
    login: "Логін",
    role: "Роль",
    position: "Посада",
    phone: "Телефон",
    avatar: "Аватар",
    password: "Пароль",
};

export const excludes = ["workhour_id", "user_id", "service_id", "appointment_id", "doctor_id", "schedule_id", "user", "createdAt", "updatedAt", "token", "password"];

export const addExcludes = ["workhour_id", "user_id", "service_id", "appointment_id", "doctor_id", "schedule_id", "user", "createdAt", "updatedAt", "token", "active", "role", "avatar", "position", "phone"];
