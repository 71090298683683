import { NavLink, useLocation } from 'react-router-dom';
import styled from "styled-components";
import admnavigation from "../../data/admnavigation.json";
import sprite from "../../assets/icons/icons.svg";
import '../../styles/variables.css';
import css from "./AdminNavigation.module.css";

const StyledLink = styled(NavLink)`
    &.active {
        color: var(--accent-color);
        background-color: #E3F3FF;
    }

`;


const AdminNavigation = () => {
    const location = useLocation();
    return (
       
        <nav className={css.menu}>
            {admnavigation.map(({ id, src, icon, text }) => (
                <StyledLink 
                    to="../../admin" 
                    className={css.link}
                    state={{ from: location, item: src}} 
                    key={id}
                >
                    <svg className={css.icon}>
                        <use href={`${sprite}${icon}`}></use>
                    </svg>

                        {text}
                </StyledLink>
             ))}
        </nav>

    )
};

export default AdminNavigation;