import sprite from "../../assets/icons/icons.svg";
import css from "./Avatar.module.css"

const Avatar = ({avatarURL}) => {

  return (

        <div className={css.circleWrapper}>
        {avatarURL ? (
            <img src={avatarURL} alt="avatarUrl" className={css.avatarImage}/>
            ) : (
            <svg className={css.defaultAvatar}>
                <use href={`${sprite}#icon-user`}></use>               
            </svg>
        )}

        </div>

  );
}; 

export default Avatar;