// import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Toaster } from "react-hot-toast";
// import {useAppointment} from "../../hooks/useAppointment";
// import {useDirectory} from "../../hooks/useDirectory";
import Button from "../../ui/buttons/Button/Button";
import MessageNotification from "../MessageNotification/MessageNotification";
import {deleteAppointment} from "../../redux/appointments/operations";
import {deleteSchedule} from "../../redux/schedules/schedulesOperations";
import {deleteDirectory} from "../../redux/directories/directoriesOperations";
import css from "./ModalDelete.module.css";

const ModalDelete = ({currentItem, onClose, text, type})=>{

    const dispatch = useDispatch();

    const onClickDelete= async()=>{
        let message;
        if (type==="appointment"){
            await dispatch(deleteAppointment(currentItem.appointment_id))
            .then(res=>{
                console.log("res=", res)
                if(res.payload.status===200){
                    message=`Прийом видалено успішно`;
                    MessageNotification("success", message);

                }else if(res.error.message==="Rejected"){
                    message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                    MessageNotification("error", message);

                }
            })
            .catch(error => {
                message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                MessageNotification("error", message);

            });


        }

        if(type==="schedule"){
            await dispatch(deleteSchedule(currentItem.schedule_id))
            .then(res=>{
                console.log("res=", res)
                if(res.payload.status===200){
                    message=`Запис із графіку видалено успішно`;
                    MessageNotification("success", message);

                }else if(res.error.message==="Rejected"){
                    message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                    MessageNotification("error", message);

                }
            })
            .catch(error => {
                message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                MessageNotification("error", message);

            });
        }

        if(type==="directory"){
            const arr=Object.keys(currentItem);
            const nameID=arr[0].slice(0,arr[0].length-3);
            const id=currentItem[arr[0]];
            const nameDirectory=nameID+"s";
            await dispatch(deleteDirectory({nameDirectory, id}))      
            .then(res=>{
                console.log("res=", res)
                if(res.payload.status===200){
                    message=`Запис із довідника видалено успішно`;
                    MessageNotification("success", message);

                }else if(res.error.message==="Rejected"){
                    message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${res.payload}`
                    MessageNotification("error", message);

                }
            })
            .catch(error => {
                message=`Щось пішло не так :( Перезавантажте сторінку та спробуйте ще раз.\n${error}`;
                MessageNotification("error", message);

            });
        }

        onClose();
    }

    const onClickCancel=()=>{
        onClose();
    }

       return (
            <div className={css.wrapper}>
            <Toaster/>
            <p>{text}</p>
            <div className={css.butWrapper}> 
                <Button 
                    type="button" 
                    onClick={onClickDelete}
                    className="ok"
                >
                    Видалити
                </Button>
                <Button
                    type="button"
                    onClick={onClickCancel}
                    className="cancel"
                >
                    Відміна
                </Button>
            </div>
            </div>
    );
}

export default ModalDelete;