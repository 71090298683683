import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppointment } from "../../hooks/useAppointment";
import { useSchedule } from "../../hooks/useSchedule";
import css from "./PeriodTypeSelect.module.css";

const StyledLink = styled(NavLink)`
    &.active {
        background-color: #cae8ff;
    }
`;

const PeriodTypeSelect = ({ changePeriod, page }) => {
    const { month, day } = useAppointment();
    const { week } = useAppointment();
    // console.log("week", week);

    const { scheduleMonth, scheduleWeek, scheduleDay } = useSchedule();
    // console.log("scheduleWeek ==>", scheduleWeek);

    let selectMonth;
    let selectWeek;
    let selectDay;
    if (page === "calendar") {
        selectMonth = month;
        selectWeek = week;
        // selectWeek = 1;
        selectDay = day;
    }
    if (page === "schedule") {
        selectMonth = scheduleMonth;

        selectWeek = scheduleWeek;
        // console.log("selectWeek", selectWeek);

        selectDay = scheduleDay;
        // console.log("selectDay", selectDay);
    }
    // console.log("selectWeek", selectWeek);

    const location = useLocation();

    return (
        <nav className={css.wrapper}>
            <StyledLink to={`month/${selectMonth}`} state={{ from: location }} className={`${css.link} ${css.left}`} onClick={() => changePeriod("month")}>
                Місяць
            </StyledLink>
            <StyledLink to={`week/${selectWeek}`} state={{ from: location }} className={`${css.link} ${css.center}`} onClick={() => changePeriod("week")}>
                Тиждень
            </StyledLink>
            <StyledLink to={`day/${selectDay}`} state={{ from: location }} className={`${css.link} ${css.right}`} onClick={() => changePeriod("day")}>
                День
            </StyledLink>
        </nav>
    );
};

export default PeriodTypeSelect;
