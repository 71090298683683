import { useSelector } from "react-redux";
import { selectSchedules, selectScheduleMonth, selectScheduleWeek, selectScheduleDay, selectScheduleYear, selectIsLoadingSchedules, selectScheduleFullDate, selectError } from "../redux/schedules/scheduleSelectors";

export const useSchedule = () => {
    const schedules = useSelector(selectSchedules);
    const scheduleMonth = useSelector(selectScheduleMonth);
    const scheduleWeek = useSelector(selectScheduleWeek);
    const scheduleDay = useSelector(selectScheduleDay);
    const scheduleYear = useSelector(selectScheduleYear);
    const isLoadingSchedules = useSelector(selectIsLoadingSchedules);
    const scheduleFullDate=useSelector(selectScheduleFullDate);
    const scheduleError=useSelector(selectError);

    return {
        schedules,
        scheduleMonth,
        scheduleWeek,
        scheduleDay,
        scheduleYear,
        isLoadingSchedules,
        scheduleFullDate,
        scheduleError
    };
};
