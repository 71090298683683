import { createPortal } from "react-dom";
import css from "./Modal.module.css";

const Modal = ({ children, onClose }) => {
    return createPortal(
        <div
            className={css.Overlay}
            //закрытие модалки по клику на бекдроп
            onClick={(event) => {
                if (event.currentTarget === event.target) {
                    onClose();
                }
            }}
        >
            <div className={css.Modal}>
                <div className={css.modalContent}>{children}</div>
            </div>
        </div>,
        document.querySelector("#popup-root")
    );
};

export default Modal;
