import logo from "../../assets/icons/logo.png";

const Logo = ({ width = 80, height = 80 }) => {
    return (
        <img
            src={logo}
            alt="logo"
            // className="rotateImage"
            width={width}
            height={height}
        />
    );
};

export default Logo;
