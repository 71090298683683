import css from "./Button.module.css";

const Button = ({ children, type, onClick, className }) => {
    return (
        <button type={type} className={className ? `${css.button} ${className}` : `${css.button}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
