import sprite from '../../../assets/icons/icons.svg';
import css from "./ButClose.module.css";


const ButClose =({onClose})=>{

    return(
        <button type="button" onClick={onClose} className={css.buttonClose} >
            <svg className={css.buttonCloseIcon}>
                <use href={`${sprite}#icon-close`}></use>
            </svg>
        </button>
    );

}

export default ButClose;