import React from "react";
import Directories from "../../components/Directories/Directories"



const AdminPage = () => {


    return (
      <>
          <h2>Редагування довідників</h2>
           <Directories/>

      </>
    );
};

export default AdminPage;