import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetAppointments, apiAddAppointment, apiDeleteAppointment, apiUpdateAppointment } from "../../api/appointments";
import getDateEnd from "../../services/getDateEnd";


export const getAppointments = createAsyncThunk("appointments/get", async (period, thunkAPI) => {
    const {
        appointments: { day, month, year },
    } = thunkAPI.getState();
    const fullMonth = (month + 1).toString().padStart(2, "0");
    const dateStart = `${year}-${fullMonth}-01`;
    const dateEnd = getDateEnd(month, year);
    const fullDay = day.toString().padStart(2, "0");
    let paramsString = {};
    if (period === "month" || period === "week") {
        paramsString = {
            ds: dateStart,
            de: dateEnd,
        };
    }
    if (period === "day") {
        paramsString = {
            date: `${year}-${fullMonth}-${fullDay}`,
        };
    }

    try {
        const response = await apiGetAppointments(paramsString);
        // console.log("getAppointments paramsString=", paramsString)
        // console.log("getAppointments response=", response)
        console.log("redux apiGetAppointments response=", response)
        return {data: response.data, status: response.status};
    } catch (error) {
        console.log("redux apiGetAppointments error=", error)
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const createAppointment = createAsyncThunk("appointments/create", async (appointment, thunkAPI) => {
    try {
        const response = await apiAddAppointment(appointment);
        return {data: response.data, status: response.status};
    } catch (error) {
        console.log("redux createAppointment error=", error.message)
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const updateAppointment = createAsyncThunk("appointments/update", async ({query, id}, thunkAPI) => {
    try {
        const response = await apiUpdateAppointment({...query}, id);
        return {data: response.data, status: response.status};
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }

});

export const deleteAppointment = createAsyncThunk("appointments/delete", async (id, thunkAPI) => {
    try {
        const response = await apiDeleteAppointment(id);
        console.log("deleteAppointment response =", response)
        return {data: response.data, status: response.status};
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

