import { Field } from 'formik';
import {titles} from "../../constants";
import css from "./DynamicFieldForm.module.css"

// Компонент для текстового типа полей
const DynamicTimeFieldForm = ({field, form}) => {
    const ArrayForMinutes=Array.from({ length: Math.floor(60 / 5) }, (_, i) => i * 5);

    return (
        <>
        <p>Введіть {titles[field]}</p>
        <label htmlFor="hours" className={css.formLabel}>Години
            <Field name="hours" required>
                {({ field }) => (
                    <select {...field} className={css.formSelect}>
                        {[...Array(24).keys()].map((hour) => (
                            <option key={`hour${hour}`} value={hour.toString().padStart(2, '0')} className={css.formOption}> 
                                {hour.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
                )}
            </Field>
        </label>
        <label htmlFor="minutes" className={css.formLabel}>Хвилини
            <Field name="minutes" required>
                {({ field }) => (
                    <select {...field} className={css.formSelect}>
                        {ArrayForMinutes.map((minute) => (
                            <option key={`minute${minute}`} value={minute.toString().padStart(2, '0')} className={css.formOption}>
                                {minute.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
                )}
            </Field>
        </label>
        </>
    );
       
};

export default DynamicTimeFieldForm;