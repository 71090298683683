import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import { useAuth } from '../../hooks/useAuth';
import Loader from "../../ui/Loader/Loader";

const LoginPage = () => {
  const { isLoading } = useAuth();
  return (
    <>
      {isLoading && <Loader />}
     
      <LoginForm />
     
    </>
  );
};

export default LoginPage;