import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/datePicker.css';
import { ReactComponent as ArrowLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/chevron-right.svg';

import { registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import { useNavigate } from 'react-router-dom';
import {
  setCurrentDay,
  setCurrentMonth,
  setCurrentYear,
  
} from '../../redux/appointments/appointmentsSlice';
import {
  setScheduleCurrentDay,
  setScheduleCurrentMonth,
  setScheduleCurrentYear,
} from '../../redux/schedules/schedulesSlice';
import { useAppointment } from "../../hooks/useAppointment";
import { useSchedule } from "../../hooks/useSchedule";

import css from "./DatePickerCustom.module.css";

registerLocale('uk', uk);

export const DatePickerCustom = ({
  filterMonth,
  setFilterMonth,
  filterYear,
  setFilterYear,
  handlePrevMonth,
  handleNextMonth,
  changePeriod,
  periodType,
  page,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {appointmentFullDate}=useAppointment();
  const {scheduleFullDate}=useSchedule();


  let reduxDate;
  if(page==="calendar"){
    reduxDate=appointmentFullDate;
  } 

  if(page==="schedule"){
    reduxDate=scheduleFullDate;
  }

  const currentDate = new Date(reduxDate);

  const filterDate = date => {
    const month = date.getMonth();
    const year = date.getFullYear();
    return month === filterMonth && year === filterYear;
  };

  const handleMonthChange = date => {
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();
    if (selectedMonth !== filterMonth || selectedYear !== filterYear) {
      setFilterMonth(selectedMonth);
      setFilterYear(selectedYear);

      if(page==="calendar"){
        dispatch(setCurrentDay(date.getDate()));
        dispatch(setCurrentMonth(date.getMonth()));
        dispatch(setCurrentYear(date.getFullYear()));
      }

      if(page==="schedule"){
        dispatch(setScheduleCurrentDay(date.getDate()));
        dispatch(setScheduleCurrentMonth(date.getMonth()));
        dispatch(setScheduleCurrentYear(date.getFullYear()));
      }
      
    }
  };

  const handleDateClick = date => {
    if (page === 'calendar' || "schedule") {
      const day = date.getDate();
      changePeriod('day');
      navigate(`/${page}/day/${day}`);
    }

    if(page==="calendar"){
      dispatch(setCurrentDay(date.getDate()));
      dispatch(setCurrentMonth(date.getMonth()));
      dispatch(setCurrentYear(date.getFullYear()));

    }

    if(page==="schedule"){
      dispatch(setScheduleCurrentDay(date.getDate()));
      dispatch(setScheduleCurrentMonth(date.getMonth()));
      dispatch(setScheduleCurrentYear(date.getFullYear()));

    }

  };

  const customHeader = ({ date, decreaseMonth, increaseMonth }) => {
    const currentMonth = new Date().getMonth();
    const selectedMonth = date.getMonth();
    const currentYear = new Date().getFullYear();
    const selectedYear = date.getFullYear();
    const decrease = () => {
      decreaseMonth();
      handlePrevMonth();
    };

    const increase = () => {
      increaseMonth();
      handleNextMonth();
    };
    return (
      <div className={css.wrapper}>
        <button className={css.datePickerButton}
          aria-label='decrease period'
          onClick={decrease}
          disabled={
            selectedMonth === currentMonth && selectedYear === currentYear
          }
          // disabledStyle={
          //   selectedMonth === currentMonth && selectedYear === currentYear
          //     ? true
          //     : false
          // }
        >
          {/* <ArrowLeft />//для чего? */}
        </button>

        <p className={css.headerTitle}>
          {periodType === 'month'
            ? currentDate.toLocaleDateString('uk', {
                month: 'long',
                year: 'numeric',
              })
            : currentDate.toLocaleDateString('uk', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
        </p>
        <button onClick={increase} aria-label='increase period' className={css.datePickerButton}>
          {/* <ArrowRight /> //для чего?*/}
        </button>
      </div>
    );
  };

  return (
    <DatePicker
      selected={currentDate}
      onMonthChange={handleMonthChange}
      onSelect={handleDateClick}
      closeOnScroll={true}
      locale="uk"
      filterDate={filterDate}
      renderCustomHeader={customHeader}
      customInput={
        <button className={css.calendarBtn} type="button" aria-label='calendar button'>
          {periodType === 'month'
            ? currentDate.toLocaleDateString('uk', {
                month: 'long',
                year: 'numeric',
              })
            : currentDate.toLocaleDateString('uk', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
        </button>
      }
    />
  );
};